img{
  max-width:100%;
  height:auto;
  &.full{
    width:100%;
  }
}
.intro,.intro-txt{
  font-size:1.2em;
  line-height: 1.6em;
}

.definition-pop{
  border-bottom: 5px solid #CBEEFA;
  box-shadow: 0px -4px 0px #CBEEFA inset;
  &:after{
    content:' \f059';
    font-family:'FontAwesome';
  }
}

/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: 767px) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
  }

  .row-offcanvas-left
  #sidebarLeft {
    left: -40%;
  }

  .row-offcanvas-left.active {
    left: 40%;
  }

  .row-offcanvas-right
  #sidebarRight {
    right: -40%;
  }

  .row-offcanvas-right.active {
    right: 40%;
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 40%;
    margin-left: 10px;
  }
}

/* Social button overrides */

.btn-facebook{
  color: #FFFFFF;
  background-color: $color-facebook;
  border-color: darken($color-facebook, 10%);
  &:hover,&:focus{
    color: #FFFFFF;
    background-color: darken($color-facebook, 10%);
  }
}

.btn-twitter{
  color: #FFFFFF;
  background-color: $color-twitter;
  border-color: darken($color-twitter, 10%);
  &:hover,&:focus{
    color: #FFFFFF;
    background-color: darken($color-twitter, 10%);
  }
}

.btn-linkedin{
  color: #FFFFFF;
  background-color: $color-linkedin;
  border-color: darken($color-linkedin, 10%);
  &:hover,&:focus{
    color: #FFFFFF;
    background-color: darken($color-linkedin, 10%);
  }
}

/* Available Color Styles */

.bg-fade-light{
  background:rgba(255,255,255,0.85);
}
.bg-fade-dark{
  background:rgba(0,0,0,0.85);
  color:#dddddd;
}

.bg-white{
    background-color:$white;
}
.bg-ghost{
    background-color:$ghost;
}
.bg-snow{
    background-color:$snow;
}
.bg-vapor{
    background-color:$vapor;
}
.bg-white-smoke{
    background-color:$white-smoke;
}
.bg-silver{
    background-color:$silver;
}
.bg-smoke{
    background-color:$smoke;
}
.bg-gainsboro{
    background-color:$gainsboro;
}
.bg-iron{
    background-color:$iron;
}
.bg-base{
    background-color:$base;
}
.bg-aluminum{
    background-color:$aluminum;
}
.bg-jumbo{
    background-color:$jumbo;
}
.bg-monsoon{
    background-color:$monsoon;
}
.bg-steel{
    background-color:$steel;
}
.bg-charcoal{
    background-color:$charcoal;
}
.bg-tuatara{
    background-color:$tuatara;
}
.bg-oil{
    background-color:$oil;
}
.bg-jet{
    background-color:$jet;
}
.bg-black{
    background-color:$black;
}
.bg-brand-primary{
    background-color:$brand-primary;
}
.bg-default{
    background-color:$default-color;
}
.bg-primary{
    background-color:$primary-color;
}
.bg-alert{
    background-color:$alert-color;
}
.bg-success{
    background-color:$success-color;
}
.bg-info{
    background-color:$info-color;
}
.bg-warning{
    background-color:$warning-color;
}
.bg-danger{
    background-color:$danger-color;
}

.txt-white{
    color:$white;
}
.txt-ghost{
    color:$ghost;
}
.txt-snow{
    color:$snow;
}
.txt-vapor{
    color:$vapor;
}
.txt-white-smoke{
    color:$white-smoke;
}
.txt-silver{
    color:$silver;
}
.txt-smoke{
    color:$smoke;
}
.txt-gainsboro{
    color:$gainsboro;
}
.txt-iron{
    color:$iron;
}
.txt-base{
    color:$base;
}
.txt-aluminum{
    color:$aluminum;
}
.txt-jumbo{
    color:$jumbo;
}
.txt-monsoon{
    color:$monsoon;
}
.txt-steel{
    color:$steel;
}
.txt-charcoal{
    color:$charcoal;
}
.txt-tuatara{
    color:$tuatara;
}
.txt-oil{
    color:$oil;
}
.txt-jet{
    color:$jet;
}
.txt-black{
    color:$black;
}
.txt-brand-primary{
    color:$brand-primary;
}
.txt-default{
    color:$default-color;
}
.txt-primary{
    color:$primary-color;
}
.txt-alert{
    color:$alert-color;
}
.txt-success{
    color:$success-color;
}
.txt-info{
    color:$info-color;
}
.txt-warning{
    color:$warning-color;
}
.txt-danger{
    color:$danger-color;
}

/* Availible styles for adding top and bottom padding */
.padding-none {
  padding-top: 0px;
  padding-bottom: 0px;
}
.padding-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media only screen and (min-width: 767px) {
  .padding-sm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.padding-md {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media only screen and (min-width: 767px) {
  .padding-md {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
.padding-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (min-width: 767px) {
  .padding-lg {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
.padding-xl {
  padding-top: 25px;
  padding-bottom: 25px;
}
@media only screen and (min-width: 767px) {
  .padding-xl {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}
.padding-xxl {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media only screen and (min-width: 767px) {
  .padding-xxl {
    padding-top: 250px;
    padding-bottom: 250px;
  }
}

/* Available styles for adding top padding */

.padding-top-none {
  padding-top: 0px;
}
.padding-top-xs {
  padding-top: 5px;
}
.padding-top-sm {
  padding-top: 10px;
}
@media only screen and (min-width: 767px) {
  .padding-top-sm {
    padding-top: 20px;
  }
}
.padding-top-md {
  padding-top: 15px;
}
@media only screen and (min-width: 767px) {
  .padding-top-md {
    padding-top: 35px;
  }
}
.padding-top-lg {
  padding-top: 20px;
}
@media only screen and (min-width: 767px) {
  .padding-top-lg {
    padding-top: 65px;
  }
}
.padding-top-xl {
  padding-top: 25px;
}
@media only screen and (min-width: 767px) {
  .padding-top-xl {
    padding-top: 125px;
  }
}
.padding-top-xxl {
  padding-top: 50px;
}
@media only screen and (min-width: 767px) {
  .padding-top-xxl {
    padding-top: 250px;
  }
}

/* Available styles for adding bottom padding */

.padding-bottom-none {
  padding-bottom: 0px;
}
.padding-bottom-xs {
  padding-bottom: 5px;
}
.padding-bottom-sm {
  padding-bottom: 10px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-sm {
    padding-bottom: 20px;
  }
}
.padding-bottom-md {
  padding-bottom: 15px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-md {
    padding-bottom: 35px;
  }
}
.padding-bottom-lg {
  padding-bottom: 20px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-lg {
    padding-bottom: 65px;
  }
}
.padding-bottom-xl {
  padding-bottom: 25px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-xl {
    padding-bottom: 125px;
  }
}
.padding-bottom-xxl {
  padding-bottom: 50px;
}
@media only screen and (min-width: 767px) {
  .padding-bottom-xxl {
    padding-bottom: 250px;
  }
}

ul.two-col-list{
  list-style-type: disc;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  list-style-position: inside;//this is important addition
  margin:0px;
  padding:0px;
  font-size:0.8em;
}
ul.no-style{
  list-style: none;
}

.clearfix,.clear-fix{
  clear:both;
}