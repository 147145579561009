footer{
	clear:both;
}

.back-top {
    display: block;
    background: #444444;
    border-radius: 5px;
    font-size: 1.6em;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 15px;
    padding: 5px 25px 10px;
    color: #ffffff;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    &.visible {
    	opacity: .50;
    	bottom: 10px;
    	visibility: visible;
	}
	&:hover,&:focus{
		opacity: .80;
		background: #222222;
	    color: #ffffff;
	}
	a, a:hover, a:focus, a:visited{
		color:#ffffff;
	}
}