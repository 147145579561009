/* -------------------------------- 

1. Auto-Hiding Navigation - Simple

-------------------------------- */

.auto-hide-header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  //height: $header-height;
  background-color: #ffffff;
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  &:after {
    clear: both;
    content: "";
    display: block;
  }
  &.is-hidden {
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  @media only screen and (min-width: 1160px) {
    //height: 80px;
  }

  .masthead{
    //height:$masthead-height;
  }
  .logo{
    a{
      margin-top:15px;
    }
    img{
      display:block;
    }
  }
  .nav-trigger{
    /* vertically align its content */
    display: table;
    height: 100%;
    padding: 0 1em;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #25283D;
    font-weight: bold;
    right: 0;
    border-left: 1px solid #f2f2f2;
    @media only screen and (min-width: 1024px) {
        display: none;
    }
    span {
      /* vertically align inside parent element */
      display: table-cell;
      vertical-align: middle;
    }
    em, em::after, em::before{
      display: block;
      position: relative;
      height: 2px;
      width: 22px;
      background-color: #25283D;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
    }
    em {
      /* this is the menu central line */
      margin: 6px auto 14px;
      -webkit-transition: background-color .2s;
      transition: background-color .2s;
    }
    em::before, em::after {
      position: absolute;
      content: '';
      left: 0;
      -webkit-transition: -webkit-transform .2s;
      transition: -webkit-transform .2s;
      transition: transform .2s;
      transition: transform .2s, -webkit-transform .2s;
    }
    em::before {
      /* this is the menu icon top line */
      -webkit-transform: translateY(-6px);
          -ms-transform: translateY(-6px);
              transform: translateY(-6px);
    }
    em::after {
      /* this is the menu icon bottom line */
      -webkit-transform: translateY(6px);
          -ms-transform: translateY(6px);
              transform: translateY(6px);
    }
  }
}
.auto-hide-header.nav-open .nav-trigger em {
  /* transform menu icon into a 'X' icon */
  background-color: rgba(255, 255, 255, 0);
}
.auto-hide-header.nav-open .nav-trigger em::before {
  /* rotate top line */
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.auto-hide-header.nav-open .nav-trigger em::after {
  /* rotate bottom line */
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.navbar-toggle .icon-bar {
  background:$link-color;
}
.masthead-logo{
  text-align:center;
  img{
    margin:5px 0;
    max-height:60px;
    width:auto;
  }
  @media only screen and (min-width: 767px) {
    text-align:left;
    img{
      margin:15px 0;
      max-height:none;
    }
  } 
}
.masthead-content{
  margin:15px 0 0;
  .masthead-copy{
    text-align:center;
    font-family: "Georgia", serif;
    font-size:1.2em;
    @media only screen and (min-width: 767px) {
      text-align:right;
      font-size:1.6em;
    }
  }
  @media only screen and (min-width: 767px) {
    margin:15px 0px;
  }
}
.masthead-nav{
  text-align:center;
  margin-bottom:0px;
  ul.navbar-nav{
    margin-top:5px;
    @media only screen and (min-width: 767px) {
      margin-top:15px;
      float:right!important;
    }
    li{
      display:inline-block;
      a{
        padding:3px 6px;
        margin:0px;
        font-size:1em;
        font-weight:600;
        text-transform:uppercase;
        color:$oil;
        background-color:transparent;
        &:hover,&:focus{
          color:$yellow;
          background-color:transparent;
        }
        @media only screen and (min-width: 767px) {
          padding:10px 18px;
          font-size:1.2em;
        }
      }
      &.active a{
        color:$yellow;
        &:hover,&:focus{
          color:$oil;
        }
      }
      &.contact a{
        color:$white;
        background:$yellow;
        border-radius:8px;
        border:1px solid $yellow;
      }
    }
  }
  @media only screen and (min-width: 767px) {
  }
  @media only screen and (max-width: 767px) {
  }

}

