@import url('https://fonts.googleapis.com/css?family=PT+Sans|Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i');

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings

$enable-flex:           true;

$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #2954B9;

/* Custom defined variables */

$white       : #FFFFFF;
$ghost       : #FAFAFA;
$snow        : #F9F9F9;
$vapor       : #F6F6F6;
$white-smoke : #F5F5F5;
$silver      : #EFEFEF;
$smoke       : #EEEEEE;
$gainsboro   : #DDDDDD;
$iron        : #CCCCCC;
$base        : #AAAAAA;
$aluminum    : #999999;
$jumbo       : #888888;
$monsoon     : #777777;
$steel       : #666666;
$charcoal    : #555555;
$tuatara     : #444444;
$oil         : #333333;
$jet         : #222222;
$black       : #000000;

// We use these as default colors throughout

$default-color: #cccccc;
$primary-color: #007573;
$alert-color: #f04124;
$success-color: #8CA84B;
$info-color: #138F8C;
$warning-color: #e99002;
$danger-color: #f04124;

// Color palette presets

$blue: $primary-color;
$ltblue: $info-color;
$drkblue: #1D5B7D;
$yellow:#12b6f6;//#FBC60D #FFC333
$orange: #e99002;
$drkorange: #c46e24;
$ltrgreen:#CBD948;
$ltgreen: #8CA84B;
$drkgreen: #00463A;

$palette-01:$primary-color;
$palette-02:$info-color;
$palette-03:$success-color;
$palette-04:$drkblue;
$palette-05:$orange;
$palette-06:$drkorange;

$link-color: $primary-color;

$btn-default-color: $oil;
$btn-default-bg: $default-color;
$btn-default-border: darken( $default-color, 10% );

$btn-primary-color: $white;
$btn-primary-bg: $primary-color;
$btn-primary-border: darken( $primary-color, 10% );

// Success appears as green
$btn-success-color: $white;
$btn-success-bg: $success-color;
$btn-success-border: darken( $success-color, 10% );

// Info appears as blue-green
$btn-info-color: $white;
$btn-info-bg: $info-color;
$btn-info-border: darken( $info-color, 10% );

// Warning appears as orange
$btn-warning-color: $white;
$btn-warning-bg: $warning-color;
$btn-warning-border: darken( $warning-color, 10% );

// Danger and error appear as red
$btn-danger-color: $white;
$btn-danger-bg: $danger-color;
$btn-danger-border: darken( $danger-color, 10% );

// Social Media colors
$color-facebook: #3B5998;
$color-twitter: #1DA1F2;
$color-linkedin: #0077B5;
$color-wordpress: #21759B;
$color-youtube: #CD201F;
$color-instagram: #E4405F;

$color-bg-01:#F9F9F7;
$color-bg-02:#E9E7DB;
