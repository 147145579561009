a {
    color: $yellow;
    text-decoration: none;
}
a[href^="tel:"] {
	color: $yellow!important;
  text-decoration: none;
}

.btn-success {
    color: #FFFFFF;
    background-color: #5ca316;
    border-color: #3a5d19;
    &:focus, &:hover{
    	background-color: #3b690e;
    }
}

.btn{
	border-radius: 10px;
	font-weight:500;
}

.btn-xl, .btn-group-xl > .btn {
    padding: 10px 18px;
    font-size: 22px;
    line-height: 1.6;
}

.btn-yellow{
	color:$oil;
	background-color:$yellow;
	border-color:$yellow;
	transition: all 0.3s ease-in-out;
	&:hover, &:focus{
		color:$white;
		background-color:darken($yellow, 15%);
		border-color:darken($yellow, 15%);
	}
}

.btn-white{
	color:$oil;
	background-color:$white;
	border-color:$oil;
	transition: all 0.3s ease-in-out;
	&:hover, &:focus{
		background-color:$iron;
		border-color:$steel;
	}
}

.btn-black{
	color:$yellow;
	background-color:$black;
	border-color:$black;
	transition: all 0.3s ease-in-out;
	&:hover, &:focus{
		background-color:$oil;
		border-color:$oil;
		color:$yellow;
	}
}
.btn-black2{
	color:$yellow;
	background-color:$black;
	border-color:$white;
	transition: all 0.3s ease-in-out;
	&:hover, &:focus{
		background-color:$oil;
		border-color:$snow;
		color:$yellow;
	}
}

body{
	color:$steel;
	font-family: 'PT Sans', sans-serif;
}

h1, h2, h3, h4, h5{
	padding-top:0px;
	margin-top:0px;
}

.intro-hl{
	font-size:2.8em;
	font-weight:600;
}

.font-georgia{
	font-family:"Georgia", serif;
}

.font-raleway{
	font-family: 'Raleway', sans-serif;
}

p {
    margin: 0 0 1.6em;
    line-height:1.8em;
}

.testimonials-section{
	p {
 	   margin: 0 0 1em;
 	   line-height:1.2em;
	}
}

img.pull-left{
	margin-right:1em;
}
img.pull-right{
	margin-left:1em;
}

.bg-yellow{
	background-color:$yellow;
}

.txt-yellow{
	color:$yellow;
}

.map-bg{
	background: $yellow url(../images/Miller_Music_Studio_Map.jpg) no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.map-bg-jack{
	width:100%;
	height:100%;
	min-height:350px;
}


.call-box{
	padding-left:50px;
	padding-right:50px;
}

.social-buttons{
	padding:0px;
	margin:0px;
	list-style:none;
	li{
		display:inline-block;
		a{
			display:block;
			color:transparent;
			transition: all 0.3s ease-in-out;
			&:hover, &:focus{
				box-shadow: 0px 2px 5px rgba(0,0,0,0.75);
			}
		}
	}
}

.copyright-section{
	font-size:1.2em;
}

.testimonial-wrap{
	margin-bottom:2em;
}
.testimonial-block{
	height:100%;
}

.service-wrap{
	margin-bottom:2em;
}
.service-block{
	background-color:$white;
	height:100%;
	.service-block-title{
		display:block;
		width:100%;
		padding:15px;
		margin:0px;
		background-color:$yellow;
		color:$white;
		font-size:1.4em;
		font-weight:600;
	}
	.service-block-image{}
	.service-block-content{
		padding:1em;
		height:100%;
		p:last-child{
			margin-bottom:0px;
		}
	}
}
.address-box{
	overflow:hidden;
}



